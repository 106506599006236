import React from 'react'
import ReactDOM from 'react-dom'

import Iframe from '../components/Iframe'


export default function renderIntoElement(element) {
  const id = element.getAttribute('data-id')

  if ( !id ) {
    console.error('[Termly] Policy embed element %o is missing a `data-id` attribute', element)
    return
  }

  ReactDOM.render(
    <Iframe
      id={ id }
    />,
    element,
  )
}
