import { DESTINATION_ELEMENT_NAME } from '../constants'

import renderIntoElement from './renderIntoElement'


export default function observeMutations(mutationList, observer) {
  mutationList.forEach(({ target }) => {
    if ( target.getAttribute('name') !== DESTINATION_ELEMENT_NAME ) {
      return
    }

    console.debug('[Termly] Detected change on element %o. Re-rendering policy.', target)

    renderIntoElement(target)
  })
}
