import React from 'react'

import { Loading as LoadingSpinner } from '@termly/react-components'

import Styles from './styles.scss'


export default function Loading() {
  return (
    <div className={ Styles.root }>
      <div className={ Styles.sizer }>
        <LoadingSpinner />
      </div>
    </div>
  )
}
