import { DESTINATION_ELEMENT_NAME } from './constants'

import observeMutations from './lib/observeMutations'
import renderIntoElement from './lib/renderIntoElement'


(function() {
  document.getElementsByName(DESTINATION_ELEMENT_NAME)
    .forEach((elem) => renderIntoElement(elem))

  const observer = new MutationObserver(observeMutations)

  observer.observe(document.body, {
    attributeFilter: ['data-id'],
    attributes: true,
    subtree: true,
  })
})()
